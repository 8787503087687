import { DatePicker, Button, TreeSelect, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BuildOutlined, FileOutlined, FolderOutlined } from '@ant-design/icons'
import fi from 'antd/es/date-picker/locale/fi_FI'
import QuickFilters from './QuickFilters'
import {
  calculatedReportSelector,
  customReportDateSelector,
  customReportTreeSelector,
  customReportsSelector
} from '../../../../redux/context/customReports/selectors'
import { setCustomReportDate } from '../../../../redux/context/customReports/actions'
import { CustomReportData } from './settings/CustomReportOrder'
import { LayoutsContext, LayoutsContextType } from './layout/LayoutContext'
import { CustomReport } from '../../../../redux/context/customReports/types'

interface PageHeaderExtraProps {
  handleReportChange: (report?: CustomReport) => void
}

const PageHeaderExtra: React.FC<PageHeaderExtraProps> = ({ handleReportChange }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const calculatedReport = useSelector(calculatedReportSelector)
  const customReportsTree = useSelector(customReportTreeSelector)
  const date = useSelector(customReportDateSelector)
  const history = useHistory()
  const { setLayoutEdit } = useContext(LayoutsContext) as LayoutsContextType
  const customReports = useSelector(customReportsSelector)

  const setDate = (newDate: string) => {
    dispatch(setCustomReportDate(newDate))
  }

  const renderTreeSelectNodes = (customReportNodes: CustomReportData[]) => {
    return customReportNodes.map(customReportNode => {
      return (
        <TreeSelect.TreeNode
          icon={customReportNode.group ? <FolderOutlined /> : <FileOutlined />}
          selectable={!customReportNode.group}
          value={customReportNode.id}
          title={customReportNode.title}
          key={customReportNode.id}
        >
          {customReportNode.children &&
            customReportNode.children.length > 0 &&
            renderTreeSelectNodes(customReportNode.children)}
        </TreeSelect.TreeNode>
      )
    })
  }

  const finLocale: typeof fi = {
    ...fi,
    lang: {
      ...fi.lang,
      fieldDateFormat: 'YYYY-MM-DD',
      fieldDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
      yearFormat: 'YYYY',
      cellYearFormat: 'YYYY'
    }
  }

  const onReportChange = (id: number) => {
    const report = customReports.find(r => r.id === Number(id))
    handleReportChange(report)
  }

  return (
    <Space>
      <QuickFilters />
      <TreeSelect
        value={calculatedReport?.id}
        showSearch
        treeIcon
        filterTreeNode={(inputval, node) => {
          return node && (node.title as string)?.toLowerCase().includes(inputval?.toLowerCase())
        }}
        onChange={onReportChange}
        style={{ width: 300 }}
        treeLine
      >
        {customReportsTree && renderTreeSelectNodes(customReportsTree as CustomReportData[])}
      </TreeSelect>
      <Button icon={<BuildOutlined />} onClick={() => setLayoutEdit(true)} />
      <Button onClick={() => history.push('/settings/custom-report/order')}>{t('header:settings')}</Button>
      <DatePicker
        style={{ width: 100, height: '100%' }}
        key="datePicker"
        showWeek
        allowClear={false}
        defaultValue={dayjs(date)}
        locale={finLocale}
        onChange={newDate => newDate && setDate(newDate.toString())}
      />
    </Space>
  )
}

export default PageHeaderExtra
