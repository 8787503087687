import { Cascader } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CategoryContext, CategoryContextType } from './CategoryContext'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'

interface ReferenceCategoryProps {
  path: string
  category: CustomReportCategory
}

export const ReferenceCategory: React.FC<ReferenceCategoryProps> = ({ path, category }: ReferenceCategoryProps) => {
  const { editObject, categories } = useContext(CategoryContext) as CategoryContextType
  const { t } = useTranslation()
  const changeValue = (c: any) => {
    if (c.value) {
      editObject(path, { ...category, value: c.value[c.value.length - 1] })
    } else {
      editObject(path, { ...category, value: undefined })
    }
  }

  function mapCategoryIds(this: string, { children, ...o }: CustomReportCategory, index: number): CustomReportCategory {
    const pathh = this ? `${this}-${index}` : `${index}`
    return {
      ...o,
      id: pathh,
      title: o.title || `${o.value}`,
      children: index < 2 ? children?.map(mapCategoryIds, pathh) : undefined
    }
  }

  const getValue = () => {
    let cascaderPath
    const selectedVal = category.value as string
    if (selectedVal) {
      const splitted = selectedVal.split('-')
      const lastPart = splitted.pop()
      cascaderPath = [splitted.join('-'), `${splitted.join('-')}-${lastPart}`]
    }
    return cascaderPath
  }

  return (
    <>
      <p>{t('customReportPage:referenceColumn')}:</p>
      <Cascader<any>
        style={{ width: 400 }}
        options={categories.map(mapCategoryIds)}
        allowClear
        onChange={value =>
          changeValue({
            value
          })
        }
        value={getValue() as any}
        fieldNames={{ label: 'title', value: 'id' }}
        placeholder={t('global:choose-here')}
      />
    </>
  )
}
