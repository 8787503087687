import React, { useContext, useEffect, useRef, useState } from 'react'
import { Tooltip, FloatButton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import {
  calculatedReportSelector,
  customReportTreeSelector,
  customReportsSelector
} from '../../../redux/context/customReports/selectors'
import CustomReportLayoutEditor from './components/layout/CustomReportLayoutEditor'
import CustomReportActions from './components/CustomReportActions'
import PageHeaderExtra from './components/PageHeaderExtra'
import CustomReportGrid from './components/CustomReportGrid'
import {
  deleteCustomReportsRequest,
  getCustomReportGroupsRequest,
  getCustomReportsRequest,
  setCalculatedReport,
  setCustomReportGroups,
  setCustomReports
} from '../../../redux/context/customReports/actions'
import { CustomReport } from '../../../redux/context/customReports/types'
import { contextCompanyIdSelector } from '../../../redux/context/company/selectors'
import { contextRequest } from '../../../redux/context/actions'
import { getFormulasRequest } from '../../../redux/context/formulas/actions'
import { AppDispatch } from '../../../redux/store'
import { CustomReportSectionsType } from '../../../redux/context/customReports/typesSection'
import CustomReportModal from './components/CustomReportModal'
import CategoryProvider from './components/categorySelection/CategoryContext'
import SectionModal from './components/section/CustomReportSectionModal'
import { loadingSelector } from '../../../redux/loading/selectors'
import Loading from '../../loading/Loading'
import { LayoutsContextType, LayoutsContext } from './components/layout/LayoutContext'
import LayoutButtons from './components/layout/LayoutButtons'
import { useSectionLoading } from './useSectionLoading'
import { CustomReportData } from './components/settings/CustomReportOrder'

export const firstReport = (data?: CustomReportData[]): CustomReport | undefined => {
  if (!data) return undefined
  let result
  function iter(a: CustomReportData) {
    if (!a?.group) {
      result = a
      return true
    }
    return Array.isArray(a.children) && a.children.some(iter)
  }
  data.some(iter)
  return result
}

const CustomReportsNew: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const calculatedReport = useSelector(calculatedReportSelector)! // TODO: calculated pitää olla että voi editaoida layout
  const customReports = useSelector(customReportsSelector)
  const [reportModalVisible, setreportModalVisible] = useState(false)
  const [sectionModalVisible, setSectionModalVisible] = useState(false)
  const [editSectionData, setEditSectionData] = useState<CustomReportSectionsType | null>(null)
  const { customReports: customReportsLoading } = useSelector(loadingSelector)
  const reportTree = useSelector(customReportTreeSelector)
  const sectionListRef = useRef(null)
  const [reportInEdit, setReportInEdit] = useState<CustomReport | null>(null)

  const { layoutEdit } = useContext(LayoutsContext) as LayoutsContextType

  const { isFinished, itemsLoading } = useSectionLoading()

  useEffect(() => {
    if (companyId) {
      dispatch(contextRequest(getCustomReportsRequest))
      dispatch(contextRequest(getCustomReportGroupsRequest))
      dispatch(contextRequest(getFormulasRequest))
    }
    return () => {
      dispatch(setCustomReports([]))
      dispatch(setCustomReportGroups([]))
      dispatch(setCalculatedReport(null))
    }
  }, [companyId])

  const handleReportChange = (report?: CustomReport) => {
    if (report) {
      dispatch(setCalculatedReport(report))
    }
  }

  const handleDelete = async (id?: CustomReport['id']) => {
    if (!id) return
    companyId && dispatch(deleteCustomReportsRequest(companyId, id))
    let newKey = ''
    if (customReports.length > 0) {
      if (customReports[0].id === id && customReports.length > 1) {
        newKey = customReports[1].id.toString()
      } else if (customReports[0].id === id && customReports.length === 1) {
        newKey = ''
      } else {
        newKey = customReports[0].id.toString()
      }
    } else {
      newKey = ''
    }
    const newReport = customReports.find(r => r.id === Number(newKey))
    handleReportChange(newReport)
  }

  const handleCancel = () => {
    setReportInEdit(null)
    setreportModalVisible(false)
  }

  const openEdit = (report: CustomReport) => {
    setReportInEdit(report)
    setreportModalVisible(true)
  }

  const editModal = (editSection: CustomReportSectionsType | null) => {
    setEditSectionData(editSection)
    setSectionModalVisible(true)
  }

  useEffect(() => {
    if (!calculatedReport?.id && reportTree.length > 0) {
      handleReportChange(firstReport(reportTree))
    }
    if (calculatedReport?.id && reportTree.length < 1) {
      dispatch(setCalculatedReport(null))
    }
  }, [reportTree])

  const layoutView = <CustomReportLayoutEditor report={calculatedReport} />

  const reportView = (
    <div ref={sectionListRef}>
      <CustomReportGrid itemsLoading={itemsLoading} calculatedReport={calculatedReport} />
    </div>
  )

  if (customReportsLoading) return <Loading />

  return (
    <>
      <CustomReportModal
        handleReportChange={handleReportChange}
        onClose={handleCancel}
        report={reportInEdit}
        visible={reportModalVisible}
      />
      {sectionModalVisible && (
        <CategoryProvider visible={sectionModalVisible} categories={(editSectionData as any)?.categories || []}>
          <SectionModal
            visible={sectionModalVisible}
            setVisible={setSectionModalVisible}
            key="add-section"
            reportId={calculatedReport?.id}
            section={editSectionData || null}
          />
        </CategoryProvider>
      )}
      <PageHeaderWrapper
        fixedHeader
        affixProps={{ offsetTop: 56 }}
        title={t('customReportPage:custom-reports')}
        subTitle={
          <Tooltip placement="right" title="info">
            <DocumentationLink />
          </Tooltip>
        }
        ghost={false}
        extra={
          layoutEdit
            ? [<LayoutButtons />]
            : [
                <PageHeaderExtra handleReportChange={handleReportChange} />,
                <CustomReportActions
                  isFinished={isFinished}
                  sectionListRef={sectionListRef}
                  editModal={editModal}
                  handleDelete={handleDelete}
                  openEdit={openEdit}
                  setreportModalVisible={setreportModalVisible}
                />
              ]
        }
      >
        <>
          {layoutEdit && calculatedReport ? layoutView : reportView}
          <FloatButton.BackTop visibilityHeight={1000} />
        </>
      </PageHeaderWrapper>
    </>
  )
}

export default CustomReportsNew
