import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Typography, Checkbox, Button, Collapse, Form } from 'antd'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  plannedDeprecationRowIdSelector,
  acceleratedDeprecationRowIdSelector,
  acceleratedDeprecationDeltaRowIdsSelector
} from '../../../../../redux/config/financialStatement/selectors'
import { depreciableBalanceSheetRowIdsSelector } from '../../../../../redux/entities/statementRows/selectors'
import { Investment } from '../../../../../types/investment/Investment'
import InvestmentFundingFormFields from './InvestmentFundingFormFields'
import { InvesmentFormContextType, InvestmentFormContext } from '../../InvestmentFormContextProvider'
import InvestmentDeprecationFields from './InvestmentDeprecationFields'
import InvestmentFormFields from './InvestmentFormFields'

interface InvestmentFormProps {
  investment?: Investment
  isExisting?: boolean
  formType?: 'investment' | 'divestment'
  page?: 'financialStatement' | 'loans'
}

const InvestmentForm: React.FC<InvestmentFormProps> = ({ investment, isExisting, formType, page }) => {
  const { t } = useTranslation()
  const { depreciable } = useContext(InvestmentFormContext) as InvesmentFormContextType
  const [taxDeprecationPlanEqual, setTaxDeprecationPlanEqual] = useState<boolean>(!investment?.taxDeprecationPlan)
  const plannedDeprecationRowId = useSelector(plannedDeprecationRowIdSelector)
  const depreciableBalanceSheetRowIds = useSelector(depreciableBalanceSheetRowIdsSelector)
  const acceleratedDeprecationRowId = useSelector(acceleratedDeprecationRowIdSelector)
  const acceleratedDeprecationDeltaRowIds = useSelector(acceleratedDeprecationDeltaRowIdsSelector)

  return (
    <>
      <Typography.Title level={4}>
        {formType === 'investment' ? t('investmentsPage:investmentFields') : t('financialStatementsPage:divestment')}
      </Typography.Title>

      <InvestmentFormFields page={page} formType={formType} />

      {formType !== 'divestment' && depreciable && (
        <>
          <Typography.Title level={4}>{t('investmentsPage:deprecationFields')}</Typography.Title>
          <InvestmentDeprecationFields
            plSelectAccountIds={plannedDeprecationRowId || []}
            bsSelectBalanceSheetRowIds={depreciableBalanceSheetRowIds || []}
            fieldParent="deprecationPlan"
          />
          <Checkbox
            style={{ marginBottom: '1.5rem' }}
            checked={taxDeprecationPlanEqual}
            onChange={() => setTaxDeprecationPlanEqual(!taxDeprecationPlanEqual)}
          >
            {t('investmentsPage:taxDeprecationFieldsEqual')}
          </Checkbox>
          {!taxDeprecationPlanEqual && (
            <>
              <Typography.Title level={4}>{t('investmentsPage:taxDeprecationFields')}</Typography.Title>
              <InvestmentDeprecationFields
                plSelectAccountIds={acceleratedDeprecationDeltaRowIds || []}
                bsSelectBalanceSheetRowIds={acceleratedDeprecationRowId ? [acceleratedDeprecationRowId] : []}
                fieldParent="taxDeprecationPlan"
                showDimension={false}
              />
            </>
          )}
        </>
      )}
      {!isExisting && (
        <>
          <Form.List key="fundings" name="fundings">
            {(fields, { add, remove }, { errors }) => (
              <>
                <Typography.Title level={4}>
                  {formType === 'investment'
                    ? t('investmentsPage:funding', { count: fields?.length })
                    : t('financialStatementsPage:divestment-counter-entries')}
                </Typography.Title>
                <Collapse
                  defaultActiveKey={[0]}
                  items={fields.map(({ key, name, ...restField }, i) => ({
                    key,
                    label:
                      formType === 'investment'
                        ? `${t('financialStatementsPage:funding')} ${name + 1}`
                        : `${t('financialStatementsPage:counter-part')} ${name + 1}`,
                    children: (
                      <InvestmentFundingFormFields
                        formType={formType || 'investment'}
                        fields={{ key, name, ...restField }}
                      />
                    ),
                    extra: i > 0 && (
                      <Button
                        danger
                        type="text"
                        size="small"
                        icon={<DeleteOutlined style={{ fontSize: '1.1rem' }} />}
                        style={{ float: 'right' }}
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    )
                  }))}
                />
                <Form.Item>
                  <Button block style={{ marginTop: 24 }} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                    {t('global:add-new')}
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      )}
    </>
  )
}

export default InvestmentForm
