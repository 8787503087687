import { Cascader, Form, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { customReportTreeSelector } from '../../../../../redux/context/customReports/selectors'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import {
  CustomReportSection,
  CustomReportSectionsType,
  SectionType
} from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariable } from '../../../../../redux/context/customReports/typesVariable'
import { CustomReportData } from '../settings/CustomReportOrder'

interface CustomReportSectionCopyFormProps {
  handleCopy: (section: Partial<CustomReportSectionsType>) => void
  handleReset: () => void
}

const CustomReportSectionCopyForm: React.FC<CustomReportSectionCopyFormProps> = ({ handleReset, handleCopy }) => {
  const { t } = useTranslation()
  const [newSectionMode, setNewSectionMode] = useState<0 | 1>(0)
  const [columns, setColumns] = useState<CustomReportCategory[]>()
  const [rows, setRows] = useState<CustomReportVariable[]>()
  const customReportTree = useSelector(customReportTreeSelector)
  const [type, setType] = useState<SectionType>('table')

  const handleChange = () => {
    const variables: CustomReportVariable[] = rows?.map(r => ({ ...r, id: undefined })) ?? []
    handleCopy({ categories: columns, variables, type })
  }

  useEffect(() => {
    handleChange()
  }, [columns, rows, type])

  const generateCascaderData = (node: CustomReportData & { sections?: CustomReportSection[]; disabled?: boolean }) => {
    if (node.children && node.children.length > 0) {
      node.children?.forEach(child => {
        generateCascaderData(child)
      })
    } else {
      // eslint-disable-next-line no-param-reassign
      node.children = node.sections
      if (node.sections && node.sections.length < 1) {
        // eslint-disable-next-line no-param-reassign
        node.disabled = true
      }
    }
    return node
  }

  return (
    <Form name="sectionCopy" layout="vertical">
      <Form.Item>
        <Radio.Group
          onChange={e => {
            e.preventDefault()
            setNewSectionMode(e.target.value)
            if (e.target.value === 0) handleReset()
            if (e.target.value === 1) handleChange()
          }}
          value={newSectionMode}
        >
          <Space direction="vertical">
            <Radio value={0}>{t('contractsPage:add-new')}</Radio>
            <Radio value={1}>{t('global:copy', { what: t('global:section').toLowerCase() })}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t('global:table-column', { count: 2 })}>
        <Cascader<CustomReportData>
          placeholder=""
          style={{ width: '100%' }}
          disabled={newSectionMode === 0}
          fieldNames={{ label: 'title', value: 'id', children: 'children' }}
          options={_.cloneDeep(customReportTree).map(c => generateCascaderData(c))}
          allowClear
          onChange={(values, data: any) => {
            setColumns(data?.[data.length - 1].categories)
            setType(data?.[data.length - 1].type)
          }}
          showSearch={{
            filter(inputValue, path) {
              return path.some(option => {
                return (option?.title as string)?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
              })
            }
          }}
        />
      </Form.Item>
      <Form.Item label={t('global:table-row', { count: 2 })}>
        <Cascader<CustomReportData>
          placeholder=""
          style={{ width: '100%' }}
          disabled={newSectionMode === 0}
          fieldNames={{ label: 'title', value: 'id', children: 'children' }}
          options={_.cloneDeep(customReportTree).map(c => generateCascaderData(c))}
          allowClear
          onChange={(values, data: any) => {
            setRows(data?.[data.length - 1].variables)
          }}
          showSearch={{
            filter(inputValue, path) {
              return path.some(option => {
                return (option?.title as string)?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
              })
            }
          }}
        />
      </Form.Item>
    </Form>
  )
}

export default CustomReportSectionCopyForm
