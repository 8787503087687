import i18next from 'i18next'
import _ from 'lodash'
import { createSelector } from 'reselect'
import { contextCompanyCountrySelector, contextCompanyFormSelector } from '../../context/company/selectors'
import { Store } from '../../types'

export const statementRowSelector = (store: Store) => store.entities.statementRows || []

const statementRowsByCompanyFormSelector = createSelector(
  contextCompanyCountrySelector,
  contextCompanyFormSelector,
  statementRowSelector,
  (country, companyForm, statementRows) => {
    return statementRows.filter(row => row.country === country && row.companyForm === companyForm)
  }
)

export const groupedStatementRowsSelector = createSelector(statementRowsByCompanyFormSelector, rows => {
  return _.groupBy(rows, 'report')
})

export const statementRowsByIdSelector = createSelector(statementRowsByCompanyFormSelector, rows => _.keyBy(rows, 'id'))

export const investableStatementRowSelector = createSelector(statementRowsByCompanyFormSelector, rows =>
  rows.filter(row => row.investable)
)

export const amendableStatementRowSelector = createSelector(statementRowsByCompanyFormSelector, rows =>
  rows.filter(row => row.amendable)
)

export const balanceSheetRowsSelector = createSelector(statementRowSelector, rows =>
  rows.filter(row => row.report === 'ASSETS' || row.report === 'LIABILITIES')
)

export const balanceSheetLeavesSelector = createSelector(balanceSheetRowsSelector, rows =>
  rows.filter(row => !row.isParent)
)

export const profitAndLossRowsSelector = createSelector(statementRowSelector, rows =>
  rows.filter(row => row.report === 'INCOME_STATEMENT')
)

export const balanceSheetArgumentOptionSelector = createSelector(balanceSheetRowsSelector, rows => {
  return rows.map(row => ({
    name: `${row.id} - ${i18next.t(`balanceSheet:${row.id}`)}`,
    value: row.id
  }))
})

export const profitAndLossArgumentOptionSelector = createSelector(profitAndLossRowsSelector, rows => {
  return rows.map(row => ({
    name: `${row.id} - ${i18next.t(`incomeStatement:${row.id}`)}`,
    value: row.id
  }))
})

export const depreciableBalanceSheetRowsSelector = createSelector(balanceSheetRowsSelector, rows => {
  return rows.filter(row => row.depreciable)
})

export const investableBalanceSheetRowsSelector = createSelector(balanceSheetRowsSelector, rows => {
  return rows.filter(row => row.investable)
})

export const depreciableBalanceSheetRowIdsSelector = createSelector(depreciableBalanceSheetRowsSelector, rows => {
  return rows.map(row => row.id)
})

export const investableeBalanceSheetRowIdsSelector = createSelector(investableBalanceSheetRowsSelector, rows => {
  return rows.map(row => row.id)
})
