import React, { useEffect, useState } from 'react'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { useBackend } from '../../services/backend'
import { notificationAction } from '../../redux/middleware/actions'
import { authTokenSelector } from '../../redux/session/authentication/selectors'
import { AppDispatch } from '../../redux/store'

interface FileUploadProps {
  taskRequestUrl: string
  actionUrl: string
  uploadText: string
  fileTypes?: string
}

const FileUpload: React.FC<FileUploadProps> = ({
  taskRequestUrl,
  actionUrl,
  uploadText,
  fileTypes
}: FileUploadProps) => {
  const { t } = useTranslation()
  const token = useSelector(authTokenSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const taskRequest = useBackend(taskRequestUrl, process.env.REACT_APP_INTEGRATION_URL)

  const dispatch: AppDispatch = useDispatch()

  const [files, setFiles] = useState<any[]>([])

  const convert = (tasks: any[]): any[] => {
    return tasks.map(task => {
      return {
        uid: task.id,
        name: task.name,
        status: task.status === 'FINISHED' ? 'done' : 'error',
        lastModified: dayjs(task.startTime).valueOf(),
        linkProps: undefined
      }
    })
  }

  const sort = (f: any[]) => {
    return f.sort((a, b) => a.lastModified - b.lastModified)
  }

  const handleRequest = () => {
    taskRequest
      .get({ urlParams: { companyId } })
      .then((tasks: any[]) => {
        setFiles(sort(convert(tasks)))
      })
      .catch(() => {
        dispatch(
          notificationAction({
            type: 'error',
            message: 'INTEGRATION_SERVER_NOT_AVAILABLE',
            description: 'CONTACT_SUPPORT'
          })
        )
      })
  }

  useEffect(handleRequest, [companyId])

  const options = {
    name: 'file',
    multiple: false,
    headers: {
      'X-Token': token || ''
    },
    action: actionUrl,
    accept: fileTypes,
    fileList: files,
    showUploadList: {
      showRemoveIcon: false
    },
    onChange(info: any) {
      const { status } = info.file
      setFiles(sort(info.fileList))
      if (status === 'done') {
        handleRequest()
        dispatch(
          notificationAction({
            type: 'success',
            message: 'IMPORT_SUCCESS'
          })
        )
      } else if (status === 'error') {
        handleRequest()
        dispatch(
          notificationAction({
            type: 'error',
            message: 'IMPORT_ERROR',
            description: 'CONTACT_SUPPORT'
          })
        )
      }
    }
  }

  return (
    <div>
      <Upload.Dragger {...options}>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">{uploadText}</p>
        <p className="ant-upload-hint">{t('integrationsPage:waiting-info')}</p>
      </Upload.Dragger>
    </div>
  )
}

export default FileUpload
