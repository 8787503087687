import dayjs from 'dayjs'
// Locales
import Fi from 'dayjs/locale/fi'
import En from 'dayjs/locale/en-gb'
import Sv from 'dayjs/locale/sv'
// Extends
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import Duration from 'dayjs/plugin/duration'
import IsBetween from 'dayjs/plugin/isBetween'
import LocaleData from 'dayjs/plugin/localeData'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import MinMax from 'dayjs/plugin/minMax'
import ObjectSupport from 'dayjs/plugin/objectSupport'
import QuarterOfYear from 'dayjs/plugin/quarterOfYear'
import RelativeTime from 'dayjs/plugin/relativeTime'
import UTC from 'dayjs/plugin/utc'

export default () => {
  dayjs.locale(Fi)
  dayjs.locale(En)
  dayjs.locale(Sv)
  dayjs.extend(CustomParseFormat)
  dayjs.extend(Duration)
  dayjs.extend(IsBetween)
  dayjs.extend(LocaleData)
  dayjs.extend(LocalizedFormat)
  dayjs.extend(MinMax)
  dayjs.extend(ObjectSupport)
  dayjs.extend(QuarterOfYear)
  dayjs.extend(RelativeTime)
  dayjs.extend(UTC)
}
