import React from 'react'
import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { valueType } from 'antd/lib/statistic/utils'

interface FormattedInputNumberProps extends InputNumberProps {
  percentage?: boolean
  block?: boolean
}

export const FormattedInputNumber = React.forwardRef(
  (
    { percentage, step, precision, min, max, block, ...restProps }: FormattedInputNumberProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const factor = percentage ? 100 : 1
    const formatter = (value?: valueType): string => {
      const formatted =
        (Number(value?.toString().replace(/\s/g, '')) * factor).toLocaleString('fi-FI', {
          style: 'decimal',
          maximumFractionDigits: precision
        }) || ''
      return formatted
    }

    const parser = (input: string | undefined): valueType => {
      const [, decimal] = (input?.replace('.', ',') || '').split(',')

      // If input is minus and one DO NOT PARSE
      if (input?.length === 0) return ''
      if (input === '-' || input === '−') return input as unknown as number

      const parsed = Number(
        (parseFloat(input?.replace(/\s/g, '').replace(',', '.').replace('−', '-') || '') / factor).toFixed(
          decimal?.length + 2 || 2
        )
      )

      return parsed
    }

    return (
      <InputNumber
        ref={ref}
        step={step ? (step as number) / factor : undefined}
        min={min ? (min as number) / factor : undefined}
        max={max ? (max as number) / factor : undefined}
        decimalSeparator=","
        formatter={formatter}
        parser={parser}
        style={block ? { width: '100%' } : undefined}
        {...restProps}
      />
    )
  }
)
