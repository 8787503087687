import { DatePicker, Input, Modal, Form, FormInstance } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useWatch } from 'antd/es/form/Form'
import { Amendment } from '../../../../../types/amendment/Amendment'
import { AmendmentFormData } from './types'
import { FormattedInputNumber } from '../../../../../components/Misc/FormattedInputNumber'
import { ReportTableRow } from '../../../../../components/Table/types'
import { useBackend } from '../../../../../services/backend'
import { contextCompanyIdSelector } from '../../../../../redux/context/company/selectors'
import AccountSelect from '../../../../../components/inputs/AccountSelect'
import { companyAccountsSelector } from '../../../../../redux/context/accounts/selectors'
import { useResetFormOnCloseModal } from '../../../../../components/Form/hooks'
import { statementRowSelector } from '../../../../../redux/entities/statementRows/selectors'
import LoadingWrapper from '../../../../../components/Misc/LoadingWrapper'

interface AmendmentFormProps {
  balanceSheetRow: ReportTableRow
  modalVisible: boolean
  handleOk: (form: FormInstance) => void
  handleCancel: (form: FormInstance) => void
  loading: boolean
}

const AmendmentForm = ({ modalVisible, loading, balanceSheetRow, handleOk, handleCancel }: AmendmentFormProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<AmendmentFormData>()
  const companyId = useSelector(contextCompanyIdSelector)
  const accounts = useSelector(companyAccountsSelector)
  const balanceSheetRowId = useWatch('balanceSheetRowId', form)
  const amendmentRequest = useBackend('/api/companies/{companyId}/amendments/{amendmentId}')
  const statementRows = useSelector(statementRowSelector)

  useResetFormOnCloseModal({
    form,
    open: modalVisible
  })

  const getAmendmentReq = (amendmentId: string): Promise<Amendment> => {
    return amendmentRequest.get({ urlParams: { companyId, amendmentId } })
  }

  const getInitialValues = async (row?: ReportTableRow) => {
    if (row?.amendment) {
      const amendment = await getAmendmentReq(row?.amendment?.id)
      return {
        ...amendment,
        date: amendment?.date ? dayjs(amendment.date, 'YYYY-MM-DD') : undefined
      }
    }

    const counterPartRowId =
      balanceSheetRow?.amendmentCounterPartRowIds?.[0] ||
      statementRows?.find(r => r.id === balanceSheetRow?.id)?.amendmentCounterPartRowIds?.[0]

    return {
      counterPartRowId,
      accountCode: balanceSheetRow.accountCode,
      balanceSheetRowId: balanceSheetRow.balanceSheetRowId,
      value: undefined,
      date: undefined,
      description: ''
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const values = await getInitialValues(balanceSheetRow)
      form.setFieldsValue(values)
    }

    fetchData()
  }, [modalVisible])

  const counterPartSelectOptions = (id: number) => {
    const counterPartRowsIds = statementRows.find(row => row.id === id)?.amendmentCounterPartRowIds || []
    return <AccountSelect balanceSheetRowIds={counterPartRowsIds} />
  }

  return (
    <Modal
      title={t('financialStatementsPage:amendment')}
      open={modalVisible}
      onOk={() => handleOk(form)}
      onCancel={() => handleCancel(form)}
      okText={t('global:save')}
      maskClosable={false}
      cancelText={t('global:cancel')}
      confirmLoading={loading}
      destroyOnClose
      forceRender={false}
    >
      <LoadingWrapper loading={amendmentRequest.loading}>
        <Form
          name="AmendmentForm"
          className="loan-form"
          layout="vertical"
          form={form}
          onValuesChange={({ counterPartAccountCode }) => {
            // Accountcodea vaihdettaessa muutetaan counterPartRowId tilin arvoa vastaamaan
            if (counterPartAccountCode) {
              const { statementRowId } = accounts?.find(a => a.code === counterPartAccountCode) || {}
              form.setFieldValue('counterPartRowId', statementRowId)
            }
          }}
          onFinish={() => handleOk(form)}
        >
          <Form.Item hidden name="id">
            <FormattedInputNumber />
          </Form.Item>
          <Form.Item hidden name="balanceSheetRowId" rules={[{ required: true, message: ' ' }]}>
            <Input />
          </Form.Item>
          <Form.Item hidden name="counterPartRowId" rules={[{ required: true, message: ' ' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="accountCode"
            rules={[{ required: true, message: ' ' }]}
            label={t('financialStatementsPage:source')}
          >
            <AccountSelect disabled />
          </Form.Item>

          <Form.Item name="value" rules={[{ required: true, message: ' ' }]} label={t('financialStatementsPage:value')}>
            <FormattedInputNumber style={{ width: '100%' }} max={balanceSheetRowId === 114 ? 0 : undefined} />
          </Form.Item>

          <Form.Item name="date" rules={[{ required: true, message: ' ' }]} label={t('financialStatementsPage:date')}>
            <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true, message: ' ' }]}
            label={t('financialStatementsPage:description')}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="counterPartAccountCode"
            rules={[{ required: true, message: ' ' }]}
            label={t('financialStatementsPage:counter-part')}
          >
            {counterPartSelectOptions(balanceSheetRowId)}
          </Form.Item>
        </Form>
      </LoadingWrapper>
    </Modal>
  )
}

export default AmendmentForm
