import React, { useEffect } from 'react'
import { Button, DatePicker, Form, Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Loan } from '../../../../types/loan/Loan'
import { FormattedInputNumber } from '../../../../components/Misc/FormattedInputNumber'
import { transformFieldData } from '../../../../utils/form'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'
import { filtersSelector } from '../../../../redux/context/filters/selectors'
import { updateExistingLoanRequest, updateNewLoanRequest } from '../../../../redux/context/loans/actions'
import { investmentsPageSelector } from '../../../../redux/pages/investments/selectors'
import { AppDispatch } from '../../../../redux/store'

interface LoanInterestProps {
  loan: Loan | null
  handleOk: (e: React.MouseEvent<HTMLElement>) => void
}

const LoanInterestModal: React.FC<LoanInterestProps> = ({ loan, handleOk }: LoanInterestProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const companyId = useSelector(contextCompanyIdSelector)!
  const { date } = useSelector(investmentsPageSelector)
  const { budgetingScenario } = useSelector(filtersSelector)
  const location = useLocation()
  const pagePath = location.pathname

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(
      {
        interestRates: loan?.interestRates
          .sort((a, b) => (dayjs(a?.date).valueOf() || 0) - (dayjs(b?.date).valueOf() || 0))
          .map(i => ({
            ...i,
            date: dayjs(i.date)
          }))
      } || []
    )
  }, [loan])

  return (
    <Modal
      destroyOnClose
      open={!!loan}
      width={700}
      title={t('loans:changing-interest')}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      maskClosable={false}
      onCancel={e => {
        form.resetFields()
        handleOk(e)
      }}
      onOk={async e => {
        try {
          const formValues = await form.validateFields()
          const formattedValues = transformFieldData(formValues, {
            date: (val: string) => dayjs(val).format('YYYY-MM-DD')
          })

          if (loan) {
            const values = {
              ...loan,
              purposes:
                loan.purposes?.map(p => {
                  return { name: p }
                }) || [],
              ...formattedValues
            }

            if (pagePath.includes('existing')) {
              dispatch(
                updateExistingLoanRequest(
                  companyId,
                  loan.id,
                  values,
                  budgetingScenario && budgetingScenario.id,
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            } else {
              dispatch(
                updateNewLoanRequest(
                  companyId,
                  loan.id,
                  values,
                  budgetingScenario && budgetingScenario.id,
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
          }

          handleOk(e)
        } catch (info) {
          console.error('Validate Failed:', info)
        }
      }}
    >
      <Form form={form} name="interest_rates" style={{ maxWidth: 700 }} autoComplete="off">
        <Form.List name="interestRates">
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    label={t('contractsPage:date')}
                    name={[field.name, 'date']}
                    rules={[{ required: true, message: t('global:required-field') }]}
                  >
                    <DatePicker format="DD.MM.YYYY" />
                  </Form.Item>

                  <Form.Item
                    label={t('loans:interest-rate')}
                    name={[field.name, 'interestRate']}
                    rules={[{ required: true, message: t('global:required-field') }]}
                  >
                    <FormattedInputNumber step={1} precision={3} max={100} percentage addonAfter="%" />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {`${t('global:add')} ${t('loans:interestRateChange')}`}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default LoanInterestModal
