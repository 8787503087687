import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper/PageHeaderWrapper'
import {
  setMultiPurposeDashboardActiveDashboard,
  resetMultiPurposeDashboard,
  resetMultiPurposeDashboardReports,
  setMultiPurposeDashboardAndActiveKey
} from '../../../redux/pages/dashboard/multiPurposeDashboard/actions'
import {
  multiPurposeDashboardPageSelector,
  multiPurposeActiveDashboardSelector,
  multiPurposeDashboardsSelector
} from '../../../redux/pages/dashboard/multiPurposeDashboard/selectors'
import { useBackend } from '../../../services/backend'

import MultiPurposeDashboardFormulaCharts from '../multiPurposeDashboard/components/MultiPurposeDashboardFormulaCharts'
import MultiPurposeParamsForm from '../multiPurposeDashboard/form/MultiPurposeParamsForm'
import LoadingWrapper from '../../../components/Misc/LoadingWrapper'
import MultiPurposeDashboardProvider from '../multiPurposeDashboard/MultiPurposeDashboardProvider'
import DocumentationLink from '../../../components/Misc/DocumentationLink'
import { Tab } from '../../../types/tab/tab'
import { generateTabs, getDashboardData } from '../multiPurposeDashboard/utils'
import { currentUserSortedCompaniesSelector } from '../../../redux/session/currentUser/selectors'
import { DashboardPageType } from '../multiPurposeDashboard/MultiPurposeDashboard'
import MultiPurposeDashboardTable from '../multiPurposeDashboard/components/MultiPurposeDashboardTable'
import MultiPurposeDashboardKeyFigureCharts from '../multiPurposeDashboard/components/MultiPurposeDashboardKeyFigureCharts'
import MultiPurposeDashboardModal from '../multiPurposeDashboard/components/MultiPurposeDashboardModal'
import MultiToolbar from './MultiCompanyToolbar'
import NoDashboards from '../multiPurposeDashboard/components/NoDashboards'
import { AppDispatch } from '../../../redux/store'
import { contextCompanySelector } from '../../../redux/context/company/selectors'

const MultiCompanyDashboard: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const { activeDashboard } = useSelector(multiPurposeDashboardPageSelector)
  const [modalVisible, setModalVisible] = useState(false)
  const dashboards = useSelector(multiPurposeDashboardsSelector)
  const company = useSelector(contextCompanySelector)
  const dashboardsRequest = useBackend('api/users/current/dashboards/multi-company')
  const dashboardsOrderRequest = useBackend('api/users/current/dashboards/multi-company/order')
  const [showCharts, setShowCharts] = useState(false)
  const multiPurposeDashboard = useSelector(multiPurposeDashboardPageSelector)
  const myCompanies = useSelector(currentUserSortedCompaniesSelector)
  const activeDashboardData = useSelector(multiPurposeActiveDashboardSelector)
  const [loading, setLoading] = useState(false)
  const [tabList, setTabList] = useState<Tab[]>([])
  const tableEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dashboardsRequest
      .get()
      .then((res: any) => {
        res.length > 0 && dispatch(setMultiPurposeDashboardAndActiveKey(res, activeDashboard))
        res.length > 0 && setTabList(generateTabs(res))
      })
      .catch()
    return () => {
      dispatch(resetMultiPurposeDashboard())
    }
  }, [company])

  useEffect(() => {
    if (
      (dashboards.length !== tabList.length && tabList.length !== 0) ||
      (dashboards.length === 1 && tabList.length === 0)
    ) {
      setTabList(generateTabs(dashboards))
    }
  }, [dashboards])

  const handleOrderChange = (order: string[]) => {
    const data = order.map((id, index) => ({ id: +id, order: index }))
    dashboardsOrderRequest
      .put({
        body: { data }
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  return (
    <MultiPurposeDashboardProvider multiPurposeDashboardPage={DashboardPageType.companies}>
      <PageHeaderWrapper
        loading={dashboardsRequest.loading}
        title={`${t('menu:/dashboard')} - ${t(`menu:/dashboard/${location.pathname.split('/').slice(-1)[0]}`)}`}
        subTitle={<DocumentationLink />}
        ghost={false}
        extra={
          <Space>
            <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
              {t('dashboardPage:new-dashboard')}
            </Button>
          </Space>
        }
        tabActiveKey={activeDashboard?.toString()}
        onTabChange={key => {
          dispatch(resetMultiPurposeDashboardReports())
          dispatch(setMultiPurposeDashboardActiveDashboard(+key))
        }}
        onOrderChange={handleOrderChange}
        tabList={tabList}
        content={<>{activeDashboard && <MultiPurposeParamsForm setLoading={setLoading} />}</>}
      >
        <LoadingWrapper loading={loading}>
          <MultiPurposeDashboardModal
            multiPurposePage={DashboardPageType.companies}
            visible={modalVisible}
            setVisible={setModalVisible}
          />
          {activeDashboard ? (
            <Card>
              <MultiToolbar
                toggleCharts={val => setShowCharts(val)}
                element={tableEl}
                exportName={activeDashboardData?.name}
              />
              <div ref={tableEl}>
                <MultiPurposeDashboardTable
                  tableData={getDashboardData(
                    activeDashboardData,
                    multiPurposeDashboard.keyfigureStatements,
                    multiPurposeDashboard.dimensionKeyfigureStatements,
                    multiPurposeDashboard.dimensions,
                    myCompanies
                  )}
                />
              </div>
              {showCharts && (
                <>
                  <MultiPurposeDashboardKeyFigureCharts />
                  <MultiPurposeDashboardFormulaCharts />
                </>
              )}
            </Card>
          ) : (
            <NoDashboards onClick={() => setModalVisible(true)} />
          )}
        </LoadingWrapper>
      </PageHeaderWrapper>
    </MultiPurposeDashboardProvider>
  )
}
export default MultiCompanyDashboard
