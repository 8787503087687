/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from 'react-redux'
import { ReportTableRow } from '../../../components/Table/types'
import { currentAndFutureFiscalYearDateRangeSelector } from '../../../redux/context/fiscalYears/selectors'
import { depreciableBalanceSheetRowIdsSelector, investableeBalanceSheetRowIdsSelector } from '../../../redux/entities/statementRows/selectors'
import { useProduct } from '../../../components/Misc/RestrictedProduct'
import { ContractProduct } from '../../../types/contract/Contract'
import { forecastCursorSelector } from '../../../redux/context/filters/selectors'

export type InvesmentFormContextType = {
  startDateDisabledDate?: (current: Dayjs) => boolean;
  depreciable?: boolean;
  isExisting: boolean;
  deprecationBsSelectBalanceSheetRowIds: () => number[];
};

export const InvestmentFormContext = React.createContext<InvesmentFormContextType | null>(null)

interface InvestmentFormContextProviderProps {
  reportTableRow?: ReportTableRow
  isExisting: boolean
  isDepreciable?: boolean
  reportType?: 'investment' | 'divestment'

}

const InvestmentFormContextProvider: React.FC<InvestmentFormContextProviderProps> = ({
  children,
  isDepreciable,
  reportTableRow,
  isExisting,
  reportType
}) => {
  const { isExact: isPro } = useProduct(ContractProduct.PRO)
  const depreciableBalanceSheetRowIds = useSelector(depreciableBalanceSheetRowIdsSelector)
  const investableBalanceSheetRowIds = useSelector(investableeBalanceSheetRowIdsSelector)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [depreciable, setDepreciable] = useState(isDepreciable || reportTableRow?.depreciable)
  const fiscalYearsDateRange = useSelector(currentAndFutureFiscalYearDateRangeSelector)
  const forecastCursor = useSelector(forecastCursorSelector)

  const startDateDisabledDate = useCallback(
    (current: Dayjs) => {
      return isExisting
        ? // Can not select days after today
          current && current >= dayjs().endOf("day")
        : // Can not select days before forecastcursor if set or current
          // fiscalyear and after future fiscalyears
          current &&
            !current.isBetween(
              forecastCursor?.clone().add(1, "month") ||
                fiscalYearsDateRange?.[0],
              fiscalYearsDateRange?.[1],
              undefined,
              "[]"
            );
    },
    [fiscalYearsDateRange, isExisting]
  );

  const deprecationBsSelectBalanceSheetRowIds = useCallback(() => {
    return depreciable ? depreciableBalanceSheetRowIds : investableBalanceSheetRowIds
  }, [depreciable])

  return (
    <InvestmentFormContext.Provider
      value={{
        // depreciable: isPro ? depreciable : false,
        // eslint-disable-next-line no-nested-ternary
        depreciable: reportType && reportType === 'divestment' ? false : isPro ? depreciable : false,
        deprecationBsSelectBalanceSheetRowIds,
        isExisting,
        startDateDisabledDate,
      }}
    >
      {children}
    </InvestmentFormContext.Provider>
  )
}

export default InvestmentFormContextProvider
