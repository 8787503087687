import { Form, TreeSelect, Select, Input, FormInstance } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GoogleSheetData } from '../../pages/settings/company/integrations/types'
import { budgetingScenariosAndForecastsSelector } from '../../redux/context/budgetingScenarios/selectors'
import { dimensionTreeSelector } from '../../redux/context/dimensions/selectors'
import { ReportDataType } from '../../redux/context/reports/types'
import { useBackend } from '../../services/backend'
import { Dimension } from '../../types/dimension/Dimension'

interface GoogleSheetFormProps {
  form: FormInstance
  type: 'budget' | 'external' | 'actuals'
  filterLockedScenarios?: boolean
}

const GoogleSheetForm: React.FC<GoogleSheetFormProps> = ({
  form,
  type,
  filterLockedScenarios
}: GoogleSheetFormProps) => {
  const { t } = useTranslation()
  const [sheetData, setSheetData] = useState<GoogleSheetData>()
  const [url, setUrl] = useState<string>()
  const [error, setError] = useState(false)
  const dimensionTree = useSelector(dimensionTreeSelector)
  const budgetingScenariosAndForecasts = useSelector(budgetingScenariosAndForecastsSelector)
  const sheetDataRequest = useBackend('/api/companies/{companyId}/integrations/google/sheets/data')

  let forecastsAndScenarios = budgetingScenariosAndForecasts
  if (filterLockedScenarios) {
    forecastsAndScenarios = forecastsAndScenarios.filter(f => !f.isLocked)
  }

  const sheetDataHandler = () => {
    url &&
      sheetDataRequest
        .get({ body: { params: { url } } })
        .then((response: GoogleSheetData) => {
          setError(false)
          setSheetData(response)
        })
        .catch(() => {
          setError(true)
          setSheetData(undefined)
        })
  }

  useEffect(sheetDataHandler, [url])

  const renderInfo = () => {
    if (error) {
      return t('integrationsPage:google-sheet-error')
    }
    if (!sheetDataRequest.loading && sheetData) {
      return sheetData.properties.title
    }
    return undefined
  }

  const renderTreeSelectNodes = (dimensions: Dimension[]) => {
    return dimensions.map(dimension => {
      return (
        <TreeSelect.TreeNode value={dimension.dimensionId} title={dimension.name} key={dimension.dimensionId}>
          {dimension.children && renderTreeSelectNodes(dimension.children)}
        </TreeSelect.TreeNode>
      )
    })
  }

  return (
    <>
      <Form.Item
        name="url"
        preserve
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        style={{ marginTop: 10 }}
        label={t('integrationsPage:url')}
        help={renderInfo()}
      >
        <Input onChange={(e: any) => setUrl(e.target.value)} />
      </Form.Item>
      <Form.Item
        label={t('integrationsPage:sheet')}
        name="sheet"
        rules={[
          {
            required: true,
            message: t('global:required-field')
          }
        ]}
        hasFeedback={
          form.getFieldValue('sheet') &&
          sheetData &&
          !sheetData.sheets.map(sheetsData => sheetsData.properties.title).includes(form.getFieldValue('sheet'))
        }
        validateStatus={
          form.getFieldValue('sheet') &&
          sheetData &&
          !sheetData.sheets.map(sheetsData => sheetsData.properties.title).includes(form.getFieldValue('sheet'))
            ? 'warning'
            : undefined
        }
      >
        <Select
          loading={sheetDataRequest.loading}
          showSearch
          optionFilterProp="children"
          disabled={!form.getFieldValue('url') || error}
        >
          {sheetData &&
            sheetData.sheets.map(sheetsData => (
              <Select.Option key={sheetsData.properties.title} value={sheetsData.properties.title}>
                {sheetsData.properties.title}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      {type === 'external' && (
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
          label={t('integrationsPage:code')}
        >
          <Input disabled={!form.getFieldValue('url') || error} />
        </Form.Item>
      )}
      {type === 'external' && (
        <Form.Item
          name="dataType"
          rules={[
            {
              required: true,
              message: t('global:required-field')
            }
          ]}
          label={t('global:type')}
        >
          <Select disabled={!form.getFieldValue('url') || error} optionFilterProp="children">
            {[ReportDataType.actuals, ReportDataType.budget, ReportDataType.forecast].map(dataType => (
              <Select.Option key={dataType} value={dataType}>
                {t(`global:${dataType}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'external' && (
        <Form.Item name="dimensionId" label={t('global:dimension')}>
          <TreeSelect
            disabled={!form.getFieldValue('url') || error}
            showSearch
            allowClear
            filterTreeNode={(inputval, node) => {
              return node && node.props.title.toLowerCase().includes(inputval.toLowerCase())
            }}
          >
            {dimensionTree && renderTreeSelectNodes(dimensionTree)}
          </TreeSelect>
        </Form.Item>
      )}
      {type !== 'actuals' && (
        <Form.Item name="budgetingScenarioId" label={t('global:scenario')}>
          {/* <Select allowClear disabled={!form.getFieldValue('url') || error} optionFilterProp="children"> */}
          <Select allowClear optionFilterProp="children">
            {forecastsAndScenarios.map(scenario => (
              <Select.Option key={scenario.id} value={scenario.id}>
                {scenario.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default GoogleSheetForm
