import { Form, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { GoogleSheet } from '../../pages/settings/company/integrations/types'
import { contextCompanyIdSelector } from '../../redux/context/company/selectors'
import { notificationAction } from '../../redux/middleware/actions'
import { useBackend } from '../../services/backend'
import GoogleSheetForm from './GoogleSheetForm'
import { AppDispatch } from '../../redux/store'

interface GoogleSheetsModalProps {
  sheets: GoogleSheet[]
  setSheets: (sheets: GoogleSheet[]) => void
  sheet?: GoogleSheet
  modalVisible: boolean
  toggleModalVisible: () => void
  type: 'budget' | 'external' | 'actuals'
  filterLockedScenarios?: boolean
}

const GoogleSheetsModal: React.FC<GoogleSheetsModalProps> = ({
  modalVisible,
  toggleModalVisible,
  sheet,
  sheets,
  setSheets,
  type,
  filterLockedScenarios
}: GoogleSheetsModalProps) => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()

  const companyId = useSelector(contextCompanyIdSelector)
  const [form] = Form.useForm<GoogleSheet>()
  const createSheetRequest = useBackend(`/api/companies/{companyId}/integrations/google/sheets/${type}`)
  const updateSheetRequest = useBackend(`/api/companies/{companyId}/integrations/google/sheets/{sheetId}`)

  const handleSubmit = () => {
    if (form) {
      form.validateFields().then((data: GoogleSheet) => {
        if (!sheet) {
          createSheetRequest
            .post({
              urlParams: { companyId },
              body: {
                data
              }
            })
            .then((response: GoogleSheet) => {
              dispatch(
                notificationAction({
                  type: 'success',
                  message: 'CREATE_INTEGRATION_SUCCESS'
                })
              )
              setSheets([...sheets, response])
              form.resetFields()
              toggleModalVisible()
            })
            .catch(() => {
              dispatch(
                notificationAction({
                  type: 'error',
                  message: 'CREATE_INTEGRATION_ERROR',
                  description: 'VALUE_NOT_VALID'
                })
              )
            })
        } else {
          updateSheetRequest
            .put({
              urlParams: { companyId, sheetId: sheet.id },
              body: {
                data
              }
            })
            .then((response: GoogleSheet) => {
              dispatch(
                notificationAction({
                  type: 'success',
                  message: 'UPDATE_INTEGRATION_SUCCESS'
                })
              )
              setSheets(sheets.map(s => (s.id !== response.id ? s : response)))
              form.resetFields()
              toggleModalVisible()
            })
            .catch(() => {
              dispatch(
                notificationAction({
                  type: 'error',
                  message: 'UPDATE_INTEGRATION_ERROR',
                  description: 'VALUE_NOT_VALID'
                })
              )
            })
        }
      })
    }
  }

  useEffect(() => {
    if (sheet) {
      form.setFieldsValue(sheet)
    }
  }, [sheet])

  const handleCancel = () => {
    form.resetFields()
    toggleModalVisible()
  }

  return (
    <Modal
      width="unset"
      style={{ maxWidth: '650px' }}
      title={sheet ? t('global:update') : t('global:add-new')}
      open={modalVisible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      maskClosable={false}
      confirmLoading={createSheetRequest.loading || updateSheetRequest.loading}
      okText={t('global:save')}
      cancelText={t('global:cancel')}
      destroyOnClose
      forceRender={false}
      afterClose={() => form.resetFields()}
    >
      <Form form={form} name="googleSheets" layout="vertical" autoComplete="off">
        <GoogleSheetForm form={form} type={type} filterLockedScenarios={filterLockedScenarios} />
      </Form>
    </Modal>
  )
}

export default GoogleSheetsModal
